import React, { Component } from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";
import MiniWidgets from "../Dashboard/MiniWidgets";
import { Link } from "react-router-dom";
import LightData from "../Maps/LightData";

const mapStyles = {
  width: "65%",
  height: "81%",
};

class Locations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: 67,
      syncInterval: "",
    };
  }

  async componentDidMount() {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      /*    body: JSON.stringify({
                   token: "8lQzN922rpRDaMbh3m5J",
                   id: "#"
               }) */
    };
    const response = await fetch(
      "https://backend.iqlabs.io/api/evChargers/transactions/byOwnerId/?token=8lQzN922rpRDaMbh3m5J&ownerId=9847040400&limit=10&page=1",

      requestOptions
    );
    const responseJson = await response.json();

    const data = responseJson.totalCount;

    let intervalId = setInterval(async () => {
      const response = await fetch(
        "https://backend.iqlabs.io/api/evChargers/transactions/byOwnerId/?token=8lQzN922rpRDaMbh3m5J&ownerId=9847040400&limit=10&page=1",

        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          /*    body: JSON.stringify({
                       token: "8lQzN922rpRDaMbh3m5J",
                       id: "#"
                   }) */
        }
      );
      const responseJson = await response.json();

      const data = responseJson.totalCount;

      if (data != this.state.sessions) this.setState({ sessions: data });
    }, 5000);

    this.setState({ sessions: data, syncInterval: intervalId });
  }

  async componentWillUnmount() {
    clearInterval(this.state.syncInterval);
  }

  render() {
    const cardData = [
      {
        key: "1",
        title: "Yashobhoomi Convention Centre",
        description: " Chargers: 3",
        info: " Sessions: 67",
      },
      {
        key: "2",
        title: "Schneider Electric-Prima Bay",
        description: " Chargers: 0",
        info: " Sessions: 0",
      },
    ];

    return (
      <div style={styles.container}>
        <div style={styles.card}>
          {cardData.map((card, index) => (
            <Link to="cms-chargers" className="text-dark">
              <div key={index} style={styles.card}>
                <h2>{card.title}</h2>
                <i className="ri-charging-pile-2-fill"></i>
                <span> {card.description}</span>
                <p>
                  <i className="ri-stack-line"></i>
                  <strong>
                    Sessions: {index === 0 ? this.state.sessions : 0}
                  </strong>
                </p>
              </div>
            </Link>
          ))}
        </div>
        <div style={styles.map}>
          <Map
            google={this.props.google}
            zoom={9}
            styles={LightData.Data}
            style={mapStyles}
            initialCenter={{
              lat: 28.5547565, // Latitude for India
              lng: 77.043062, // Longitude for India
            }}
          >
            <Marker
              position={{
                lat: 19.1239448, // Latitude for Schneider
                lng: 72.8933322, // Longitude for Schneider
              }}
              title={"Bangalore"}
              name={"Bangalore"}
            />
            <Marker
              position={{
                lat: 28.5547565, // Latitude for Bangalore
                lng: 77.043062, // Longitude for Bangalore
              }}
              title={"Yashobhhomi"}
              name={"Yashobhoomi"}
            />
          </Map>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: "flex",
    height: "100vh",
    marginTop: "100px",
  },
  cardContainer: {
    width: "25%",
    padding: "20px",
    boxSizing: "border-box",
    backgroundColor: "#f8f9fa",
    borderRight: "1px solid #dee2e6",
    overflowY: "auto",
  },
  card: {
    marginBottom: "20px",
    padding: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0 0 5px rgba(0,0,0,0.1)",
    borderRadius: "5px",
  },
  map: {
    width: "75%",
    height: "100%",
  },
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBcgqRhNZTzePlgvGoB3mgYqE_fEkL1yuA",
})(Locations);
